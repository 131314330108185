import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SharedService} from '../../shared/shared.service';
import {HomeService} from 'src/app/home/home.service';
import * as moment from 'moment';

@Component({
    selector: 'search-history-advanced-search-dialog',
    templateUrl: 'search-history-advanced-search-dialog.html',
    styleUrls: ['./search-history-advanced-search.component.scss']
})
export class AdvancedSearchDialog implements OnInit{
    header = "Search Options"
    users: Array<String>;
    maxDate: moment.Moment = moment();
    isIncorrectDate: boolean = false;
    searchTypeError: boolean = false;
    dateError: string;
    defaultUserValue: String;
    selectedSearchTypes: Array<String> = [];
    isAllSearchTypeChecked: boolean = false;
    
    constructor(public dialogRef: MatDialogRef<AdvancedSearchDialog>,
                public sharedService: SharedService,
                private homeService: HomeService,
                @Inject(MAT_DIALOG_DATA) public data: any) { }


    ngOnInit() {
        const currentUser = this.homeService.userDetails.email;
        this.users = [currentUser, "All Users"];
        this.defaultUserValue = this.data.searchCriteria.allUser ? this.users[1] : this.users[0];
        if(this.data.searchCriteria.searchTypes) {
            this.selectedSearchTypes = this.data.searchCriteria.searchTypes;
        }
        if (this.selectedSearchTypes.length === this.data.searchTypes.length) {
            this.isAllSearchTypeChecked = true;
        }
    }

    changeUser(value: string): void {
        this.data.searchCriteria.allUser = value !== this.users[0];
    }

    changeAllSearchType(value:boolean): void {
        this.isAllSearchTypeChecked = value;
        if(value) {
            this.searchTypeError = false;
            this.selectedSearchTypes = JSON.parse(JSON.stringify(this.data.searchTypes));
        } else {
            this.selectedSearchTypes = [];
        }
    }

    changeSearchType(value:boolean, data:string): void {
        if (value) {
            this.searchTypeError = false;
            if (this.selectedSearchTypes.length === 0) {
                this.selectedSearchTypes.push(data);
            } else {
                const index = this.selectedSearchTypes.findIndex(searchType => searchType === data);
                if (index === -1) {
                    this.selectedSearchTypes.push(data);
                }
            }
            if (this.selectedSearchTypes.length === this.data.searchTypes.length) {
                this.isAllSearchTypeChecked = true;
            }
        } else {
            this.isAllSearchTypeChecked = false;
            const index = this.selectedSearchTypes.findIndex(searchType => searchType === data);
            if (index >= 0) {
                this.selectedSearchTypes.splice(index, 1);
            }
        }
    }

    includeSearchesWithoutResults(value: boolean): void {
        this.data.searchCriteria.includeSearchesWithoutResults = value;
    }

    changeDate(value:any, fieldName:string): void {
        this.isIncorrectDate = false;
        if(fieldName === "fromDate") {
            this.data.searchCriteria.fromDateTime = value ? value.toISOString() : undefined;
        } else {
            this.data.searchCriteria.toDateTime = value ? value.toISOString() : undefined;
        }
    }

    clearToDate() {
        delete this.data.searchCriteria["toDateTime"];
    }

    quickDateSearch(type: string): void {
        switch(type) {
            case "today":
                this.data.searchCriteria.fromDateTime = moment().startOf("day").toISOString();
                break;
            case "this-week":
                this.data.searchCriteria.fromDateTime = moment().startOf("week").toISOString();
                break;
            case "last-15-minutes":
                this.data.searchCriteria.fromDateTime = moment().subtract(15,"minutes").toISOString();
                break;
            case "last-30-minutes":
                this.data.searchCriteria.fromDateTime = moment().subtract(30,"minutes").toISOString();
                break;
            case "last-1-hour":
                this.data.searchCriteria.fromDateTime = moment().subtract(1,"hours").toISOString();
                break;
            case "last-24-hours":
                this.data.searchCriteria.fromDateTime = moment().subtract(24,"hours").toISOString();
                break;
            case "last-7-days":
                this.data.searchCriteria.fromDateTime = moment().subtract(7,"days").toISOString();
                break;
            case "last-30-days":
                this.data.searchCriteria.fromDateTime = moment().subtract(30,"days").toISOString();
                break;
            case "last-90-days":
                this.data.searchCriteria.fromDateTime = moment().subtract(90,"days").toISOString();
                break;
            case "last-1-year":
                this.data.searchCriteria.fromDateTime = moment().subtract(1,"years").toISOString();
                break;
        }
    }

    search(): void {
        if(this.selectedSearchTypes.length === 0) {
            this.searchTypeError = true;
            return;
        }
        if(this.data.searchCriteria.fromDateTime && moment(this.data.searchCriteria.fromDateTime).isAfter(moment().toISOString())) {
            this.dateError = "Invalid 'From Date'";
            this.isIncorrectDate = true;
            return;
        }
        if(this.data.searchCriteria.toDateTime && moment(this.data.searchCriteria.toDateTime).isAfter(moment().toISOString())) {
            this.dateError = "Invalid 'To Date'";
            this.isIncorrectDate = true;
            return;
        }
        if(this.data.searchCriteria.fromDateTime && this.data.searchCriteria.toDateTime &&
            moment(this.data.searchCriteria.fromDateTime).isAfter(moment(this.data.searchCriteria.toDateTime))) {
            this.dateError = "Invalid date range";
            this.isIncorrectDate = true;
            return;
        }
        this.data.searchCriteria.searchTypes = this.selectedSearchTypes;
        this.dialogRef.close(this.data.searchCriteria);
    }

    close(): void {
        this.dialogRef.close(false);
    }

    reset(): void {
        this.data.searchCriteria.allUser = false;
        this.data.searchCriteria.fromDateTime = moment().subtract(24,"hours").toISOString();
        this.data.searchCriteria.includeSearchesWithoutResults = false;
        this.data.searchCriteria.searchTypes = JSON.parse(JSON.stringify(this.data.searchTypes));
        delete this.data.searchCriteria["toDateTime"];
        delete this.data.searchCriteria["incidentId"];
        
        this.sharedService.setSearchHistoryCriteria(this.data.searchCriteria);
        this.defaultUserValue = this.data.searchCriteria.allUser ? this.users[1] : this.users[0];
        this.selectedSearchTypes = JSON.parse(JSON.stringify(this.data.searchTypes));
        this.isAllSearchTypeChecked = true;
    }

}
