import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HomeService} from '../../home/home.service';
import {UsersService} from '../users.service';
import {SharedService} from '../../shared/shared.service';
import {Alerts} from '../../shared/enums/alerts';
import {Roles} from '../../shared/enums/roles';
import {UserBase} from "../UserBase";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
    ListParentOrganizationsComponent
} from "../../organizations/list-parent-organizations/list-parent-organizations.component";
import { UserTo } from 'src/app/shared/models/userTo';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent extends UserBase implements OnInit {
    
    selectedOrgName: string;
    loggedInUser: UserTo;
    user: any = {};
    orgDialogRef: MatDialogRef<unknown, any>;
    ssoUser: any = false;

    constructor(@Inject(MAT_DIALOG_DATA) public selectedOrg, 
                private usersService: UsersService, 
                public dialogRef: MatDialogRef<CreateUserComponent>,
                private homeService: HomeService, 
                public sharedService: SharedService,
                private dialog: MatDialog) {
        super();
    }

    ngOnInit() {
        this.loggedInUser = new UserTo(this.homeService.userDetails);
        this.header = "Create User";
        this.initializaData();
    }

    initializaData() {
        if (this.loggedInUser.roles.includes(Roles.SUPER) && this.selectedOrg) {
            this.user.org = JSON.parse(JSON.stringify(this.selectedOrg));
        } else {
            this.user.org = JSON.parse(JSON.stringify(this.loggedInUser.org));
        }
        this.selectedOrgName = this.user.org.name;
    }

    selectOrg() {
        this.orgDialogRef = this.dialog.open(ListParentOrganizationsComponent, {
            data: this.user.org?.clientId
        });
        this.orgDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.user.org = result;
                this.selectedOrgName = result.name;
            }
        });
    }

    isValidData() {
        this.markFormControlsAsTouched();
        return this.email.valid && this.firstName.valid && this.lastName.valid && this.roles.valid && this.phone.valid;
    }

    createUser() {
        if (this.isValidData()) {
            this.loading = true;
            this.usersService.createUser(this.user).subscribe({
                next: data => {
                    this.loading = false;
                    this.sharedService.showAlert(Alerts.SUCCESS, 'User created successfully');
                    this.dialogRef.close(true);
                },
                error: error => {
                    this.loading = false;
                    this.sharedService.handleErrors(error,
                        [{ 
                            "condition": (error.status === 400 && Object.keys(error.error).length > 0), 
                            "msg": error.error[Object.keys(error.error)[0]]
                        }], 
                        "Unable to create user");
                },
                complete: () => {}
            });
        }
    }

    resetUser() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.user = {};
                    this.initializaData();
                }
            });
        } else {
            this.user = {};
            this.initializaData();
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

}
