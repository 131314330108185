import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {UserTo} from '../shared/models/userTo';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from '../../assets/config/config-svc.service';
import {HomeService} from '../home/home.service';
import {SharedService} from '../shared/shared.service';
import {Page} from 'puppeteer';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    updateUserList = new Subject<void>();
    updateUserList$ = this.updateUserList.asObservable();

    updateNewUserList = new Subject<void>();
    updateNewUserList$ = this.updateNewUserList.asObservable();

    constructor(public http: HttpClient, public homeService: HomeService, public sharedService: SharedService) {
    }

    getHeaders() {
        return this.getHeadersForContent('application/json', 'json', '');
    }

    getHeadersOrg(org) {
        return this.getHeadersForContent('application/json', 'json', org.clientId);
    }

    getHeadersForContent(contentType, responseType, clientId) {
        if (!clientId) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': contentType,
                    'transactionId': this.sharedService.generateUUID(),
                    'reportType': 'All',
                }),
                withCredentials: true,
                responseType: responseType
            };
            return httpOptions;
        } else {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': contentType,
                    'transactionId': this.sharedService.generateUUID(),
                    'reportType': 'All',
                    'client-id': clientId
                }),
                withCredentials: true,
                responseType: responseType
            };
            return httpOptions;
        }
    }

    public createUser(payload) {
        return this.http.post(ConfigService.config.userServiceUrl + '/user/create',
            payload,
            this.getHeaders()).pipe(map((res: any) => res));
    }

    public provisionUser(payload) {
        return this.http.post(ConfigService.config.userServiceUrl + '/user/provision',
            payload,
            this.getHeaders()).pipe(map((res: any) => res));
    }

    public updateUser(payload) {
        return this.http.put(ConfigService.config.userServiceUrl + '/user/update',
            payload,
            this.getHeaders()).pipe(map((user: any) => user));
    }

    public getAllUsers(): Observable<UserTo[]> {
        return this.http.get<UserTo[]>(ConfigService.config.userServiceUrl + '/users',
            this.getHeaders()).pipe(map((users => this.setUsersDataInModel(users))));
    }

    getAllUnprovisionedUsers(filterValue, sortActive, sortDirection, pageIndex, pageSize): Observable<Page> {
        return this.http.get<Page>(ConfigService.config.userServiceUrl + '/unprovisionedUsers?SEARCH_TERM=' + filterValue +
            '&sortActive=' + sortActive +
            '&sortDirection=' + sortDirection +
            '&page=' + pageIndex +
            '&size=' + pageSize,
            this.getHeaders()).pipe(map((users => users)));
    }

    public deleteUnprovisionedUser(email) {
        return this.http.delete(ConfigService.config.userServiceUrl + '/newUser/' + email,
            this.getHeaders()).pipe(map((res: any) => res));
    }

    public deleteUser(payload) {
        return this.http.delete(ConfigService.config.userServiceUrl + '/user/' + payload.email,
            this.getHeadersOrg(payload.org)).pipe(map((res: any) => res));
    }

    public bulkDeleteUsers(payload) {
        return this.http.post(ConfigService.config.userServiceUrl + '/user/bulkDelete', payload,
            this.getHeaders()).pipe(map((user => user)));
    }

    setUsersDataInModel(usersData) {
        const userTo = [];
        if (usersData) {
            usersData.forEach(element => {
                if (element.org) {
                    const eachUser = new UserTo(element);
                    userTo.push(eachUser);
                }
            });
        }
        return userTo;
    }

    getSharedSecret(uuid) {
        return this.http.get<any>(ConfigService.config.userServiceUrl + '/organization/secret/' + uuid,
            this.getHeadersForContent('text/html', 'text', '')
        );
    }
}
