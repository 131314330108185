import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UserTo} from '../../shared/models/userTo';
import {HomeService} from '../../home/home.service';
import {UsersService} from '../users.service';
import {SharedService} from '../../shared/shared.service';
import {Alerts} from '../../shared/enums/alerts';
import {UserBase} from '../UserBase';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-update-user',
    templateUrl: './update-user.component.html',
    styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent extends UserBase implements OnInit {
    resetData: UserTo;
    userData: UserTo;

    constructor(@Inject(MAT_DIALOG_DATA) public data: UserTo,
                public dialogRef: MatDialogRef<UpdateUserComponent>,
                public userService: UsersService,
                public homeService: HomeService,
                public sharedService: SharedService) {
        super();
    }

    ngOnInit() {
        this.userData = new UserTo(this.data);
        this.resetData = new UserTo(this.data);
        this.header = 'Update User';
        this.title = this?.userData?.email;
    }

    isValidData() {
        this.markFormControlsAsTouched();
        return this.email.valid && this.firstName.valid && this.lastName.valid && this.roles.valid &&
            this.phone.valid;
    }

    updateUser() {
        if (this.isValidData()) {
            if (this.resetData.firstName !== this.userData.firstName ||
                    this.resetData.lastName !== this.userData.lastName ||
                    this.resetData.phone !== this.userData.phone ||
                    JSON.stringify(this.resetData.roles) !== JSON.stringify(this.userData.roles)) {
                this.loading = true;
              
                this.userService.updateUser(this.userData).subscribe({
                    next: data => {
                        this.loading = false;
                        if (JSON.stringify(this.resetData.roles) !== JSON.stringify(this.userData.roles)) {
                            this.sharedService.showConfirmAlert(Alerts.SUCCESS, 'User Updated successfully \n\n User \'' +
                                this.userData.firstName + ' ' + this.userData.lastName + '\' needs to log out before role change will be applied');
                        } else {
                            this.sharedService.showAlert(Alerts.SUCCESS, 'User Updated successfully');
                        }
                        this.dialogRef.close(data);
                    },
                    error: error => {
                        this.loading = false;
                        this.sharedService.handleErrors(error,
                            [{ 
                                "condition": (error.status === 400 && Object.keys(error.error).length > 0), 
                                "msg": error.error[Object.keys(error.error)[0]]
                            }], 
                            "Unable to update user");
                    },
                    complete: () => {}
                });
            } else {
                this.sharedService.showAlert(Alerts.ERROR, 'Please modify the details to update');
                this.markFormControlsAsTouched();
            }
        } else {
            this.sharedService.showAlert(Alerts.ERROR, 'Please provide the correct details to update');
            this.markFormControlsAsTouched();
        }

    }

    resetUser() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.userData = new UserTo(this.resetData);
                }
            });
        } else {
            this.userData = new UserTo(this.resetData);
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

    allowRoleChange(role: string): boolean {
        if (this.homeService.userDetails.uuid === this.userData.uuid) {
            return false;
        }
        return this.homeService.userDetails.roles.includes(role);
    }
}
