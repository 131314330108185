import {Component, OnInit, ViewChildren} from '@angular/core';
import {LoginService} from './login.service';
import {ActivatedRoute, Router} from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { Alerts } from '../shared/enums/alerts';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CookieService } from '../shared/cookie.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    loginMsg =
    `This system is for the use of authorized users only.
    All usage may be monitored. Anyone using this system expressly 
    consents to such monitoring and is advised that if such monitoring 
    reveals possible evidence of criminal activity system personnel may 
    provide the evidence from such monitoring to law enforcement officials. 
    Individuals found to be using this web application without authority, 
    or in excess of their authority will have their access rights removed.
    The user acknowledges "opt-in" to receiving SMS (text messages) and email
    required for multi-factor authentication and account verification`;
    
    logoutMsg = `You have successfully logged out of PowerData`
    buttonText;
    errorMessage: string;
    login: boolean;
    msg;
    redirectUri: string = '';

    readonly REDIRECT_URL = 'redirectUrl';

    constructor(public loginService: LoginService,
                public sharedService: SharedService,
                public router: Router,
                public activatedRoute: ActivatedRoute,
                private cookieService: CookieService) {
        this.activatedRoute.url.subscribe((val) => {
            this.errorMessage = val[0].parameters.error;
        });
    }

    ngOnInit() {
        this.sharedService.redirectUri$.subscribe((redirectUri: string) => {
            this.redirectUri = redirectUri;
        });

        if (!this.redirectUri && this.cookieService.getCookie(this.REDIRECT_URL)) {
            this.redirectUri = this.cookieService.getCookie(this.REDIRECT_URL);
        }
        // verify if already logged in
        const token = localStorage.getItem(this.sharedService.ID_TOKEN);
        if (token && token !== 'null') {
            this.router.navigate(['appconsole']);
            return;
        }

        switch (this.activatedRoute.snapshot.params['action']) {
            case 'login':
                this.msg = this.loginMsg;
                this.buttonText = 'Accept & Continue';
                const error = this.activatedRoute.snapshot.queryParamMap.get('error');
                if (error != null) {
                    if (error.includes('invalid_user_info_response')) {
                        Swal.fire({
                            width: 700,
                            padding: '3em',
                            icon: Alerts.WARNING,
                            title: 'It appears that you currently don\'t have access. If you just signed up, your registration needs to be approved by an administrator. You will receive an email upon approval.',
                            showConfirmButton: false,
                            timer: 0
                        }).then(() => {
                            this.loginService.signOutAndRedirectToLogin();
                        });
                    } else {
                        this.loginService.signOutAndRedirectToLogin();
                    }
                }
                break;
            case 'logout':
                this.msg = this.logoutMsg;
                this.buttonText = 'Login Again';
                break;
            default:
                console.error('Invalid action: ' + this.activatedRoute.snapshot.params['action']);
        }
    }

    signIn() {
        if (this.redirectUri) {
            this.loginService.signInAndRedirect(this.redirectUri);
        } else {
            this.loginService.signIn();
        }
    }
}
