import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HomeService} from '../home/home.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {SharedService} from '../shared/shared.service';
import {Roles} from "../shared/enums/roles";
import {LoginService} from "../login/login.service";
import {ListParentOrganizationsComponent} from "../organizations/list-parent-organizations/list-parent-organizations.component";
import { OrganizationTo } from '../shared/models/organizationTo';
import { UserTo } from '../shared/models/userTo';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

    isSearchEnabled: boolean;
    isHistoryChecked: boolean;
    isDashboardChecked: boolean;
    isSuperUser: boolean;
    isAdminUser: boolean;
    hasViewRole: boolean;
    isShortWebLink:boolean;

    selectedOrg: OrganizationTo;
    loggedInUser: UserTo;
    currentNavigator = '';
    orgDialogRef: MatDialogRef<unknown, any>;
    

    constructor(private loginService: LoginService,
                private homeService: HomeService, 
                private dialog: MatDialog, 
                private sharedService: SharedService,
                private activeRouter: ActivatedRoute) {
        
        this.sharedService.loggedInUser$.subscribe(
            loggedInUser => {
                if(loggedInUser) {
                    this.isSuperUser = loggedInUser.roles.includes(Roles.SUPER);
                    this.isAdminUser = loggedInUser.roles.includes(Roles.ADMIN);
                    this.hasViewRole = loggedInUser.roles.includes(Roles.VIEW);
                    this.isSearchEnabled = loggedInUser.roles.includes(Roles.SEARCH) || this.isSuperUser;
                    this.loggedInUser = loggedInUser;
                    this.selectedOrg = new OrganizationTo(loggedInUser.org);
                }
            }
        );
    }

    ngOnInit():void {
        this.isShortWebLink = this.activeRouter.snapshot.url[0]?.toString() === 'shortweblink';
    }

    selectOrg() {
        this.orgDialogRef = this.dialog.open(ListParentOrganizationsComponent, {
            data: this.selectedOrg?.clientId
        });
        this.orgDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedOrg = result;
                this.sharedService.setCurrentOrgId({'orgId': this.selectedOrg.clientId, 'navigate': this.currentNavigator});
            }
        });
    }

    ngOnDestroy() {

    }

    signOut() {
        localStorage.setItem(this.sharedService.ID_TOKEN, null);
        this.loginService.signOut();
    }

    openUserInfo() {
        this.resetAllCheck();
        this.homeService.navigate.next('loginUser');
    }

    navigateToUser() {
        this.resetAllCheck();
        this.homeService.navigate.next('listUser');
    }

    navigateToProvisionUser() {
        this.resetAllCheck();
        this.homeService.navigate.next('provisionUser');
    }

    navigateToSearch() {
        this.resetAllCheck();
        this.homeService.navigate.next('powerSearch');
    }

    navigateToHistory() {
        this.resetAllCheck();
        this.isHistoryChecked = true;
        this.currentNavigator = 'searchHistory';
        this.homeService.navigate.next('searchHistory');
        this.sharedService.setCurrentOrgId({'orgId': this.selectedOrg.clientId, 'navigate': this.currentNavigator});
    }

    navigateToDashboard() {
        this.resetAllCheck();
        this.isDashboardChecked = true;
        this.currentNavigator='dashboard';
        this.homeService.navigate.next('dashboard');
        this.sharedService.setCurrentOrgId({'orgId': this.selectedOrg.clientId, 'navigate': this.currentNavigator});
    }

    navigateToZeroTouch() {
        this.resetAllCheck();
        this.homeService.navigate.next('zeroTouch');
    }

    navigateToOrganization() {
        this.resetAllCheck();
        this.homeService.navigate.next('listOrganization');
    }

    navigateToEntitlements() {
        this.resetAllCheck();
        this.homeService.navigate.next('listEntitlements');
    }

    navigateToSearchTypes() {
        this.resetAllCheck();        
        this.homeService.navigate.next('listSearchTypes');
    }

    navigateToProviders() {
        this.resetAllCheck();
        this.homeService.navigate.next('listProviders');
    }

    navigateToTextBlocks() {
        this.resetAllCheck();
        this.homeService.navigate.next('listTextBlocks');
    }

    navigateToIotGateway() {
        this.resetAllCheck();
        this.homeService.navigate.next('listPredefinedTextMessages');
    }

    navigateToGeofence() {
        this.resetAllCheck();
        this.homeService.navigate.next('listGeofence');
    }

    resetAllCheck() {
        this.isHistoryChecked = false;
        this.isDashboardChecked = false;
    }

    openHelp() {
        const dialogRef = this.dialog.open(HelpClass);
        dialogRef.afterClosed().subscribe(() => {
        });
    }
}


@Component({
    selector: 'help-class',
    templateUrl: './help-dialogue.html',
    styleUrls: ['./navigation.component.scss']
})
export class HelpClass {
    version: string = require('../../../package.json').version;
    object: any;
    header = "About Power Data"

    constructor(public dialogRef: MatDialogRef<HelpClass>) {
    }

    close() {
        this.dialogRef.close(false);
    }
}
